app.navigation = () => {


  function init() {
    document.querySelector('#menuToggle')
            .addEventListener('click', toggleMenu);
  }

  let toggleMenu = (e) => {
    $('#menu').toggleClass('show-nav');
  };

  return init();
};
